// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-7-principles-tsx": () => import("./../../../src/pages/7-principles.tsx" /* webpackChunkName: "component---src-pages-7-principles-tsx" */),
  "component---src-pages-codeofconduct-tsx": () => import("./../../../src/pages/codeofconduct.tsx" /* webpackChunkName: "component---src-pages-codeofconduct-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-templates-past-event-past-event-tsx": () => import("./../../../src/templates/PastEvent/PastEvent.tsx" /* webpackChunkName: "component---src-templates-past-event-past-event-tsx" */),
  "component---src-templates-speaker-tsx": () => import("./../../../src/templates/Speaker.tsx" /* webpackChunkName: "component---src-templates-speaker-tsx" */)
}

